.pageContainer {
  margin-top: 40px;
}

.sub-parts {
  @include GetFlex(1);
  flex-direction: column;
  margin: 0 60px 1% 60px;
}

.partHeading {
  @include BoldFont($h2-text, $primary-text-color, 24px);
}

.subHeading {
  @extend .partHeading;
  font-size: $h3-text;
}

.partText {
  @include RegularFont(16px, $primary-text-color, 24px);
}

div#subpart-15 ul {
  margin-top: 12px;
  margin-left: 50px;
}

div#subpart-22 ul {
  margin-top: 12px;
  margin-left: 50px;
}

div#subpart-15 ul {
  list-style: none;
}

div#subpart-15 li::before {
  content: "\2022";
  color: $secondary-text-color;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

div#subpart-22 ul {
  list-style: none;
}

div#subpart-22 li::before {
  content: "\2022";
  color: $secondary-text-color;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.pageHeadingH1 {
    @include RegularFont( $h1-text, $primary-text-color, 62px);
    margin: 60px 60px 40px 60px;
}

.partText ol {
  margin-left: 20px;
}
