#toogle-button {
  display: flex;
  #toogle-text {
    @include GetFlex(3);
    @include RegularFont(16px, $secondary-text-color, $medium-button-height);
  }

  #toogle-switch {
    @include GetFlex(1);
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      & input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .sliderRight:before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
      }

      .slider,
      .sliderRight {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $secondary-text-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 14px;
          width: 14px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        &.round {
          border-radius: 34px;
        }

        &.round:before {
          border-radius: 50%;
        }
      }
    }
  }
}

.custom-btn-primary {
  @include RegularFont(16px, $white, $medium-button-height);
  min-height: 45px;
  cursor: pointer;
  border-width: 0;
  border-radius: 6px;
  background: $primary-dark;
  padding: 0 10px;
  &:hover,
  &:focus {
    //@include BoldFont(16px, $white, 23px);
    font-weight: bold;
    box-shadow: 0px 4px 7px 5px $button-primary-shadow;
  }

  &.regular {
    @include RegularFont(18px, $white, $large-button-height);
  }
  &.medium {
    @include RegularFont(16px, $white, $medium-button-height);
  }
  &.info {
    background: $secondary-text-color;
    &:hover {
      box-shadow: 2px 3px 3px 3px $button-disabled-shadow;
    }
    &.active, &:focus {
      font-weight: bold;
      box-shadow: 2px 3px 3px 3px $button-disabled-shadow;
    }
  }
}

.custom-btn-primary-disabled {
  @include RegularFont(16px, $white, $medium-button-height);
  height: 45px;
  background: $primary-light;
  border-radius: 6px;
  border-width: 0;
}

.custom-btn-secondary {
  @include RegularFont(16px, $primary-dark, $medium-button-height);
  height: 45px;
  cursor: pointer;
  border-width: 0;
  border-radius: 6px;
  border: 1px solid $primary-dark;
  background: $white;

  &.regular {
    @include RegularFont(18px, $primary-dark, $large-button-height);
  }
  &.medium {
    @include RegularFont(16px, $primary-dark, $medium-button-height);
  }

  &:hover,
  &:focus {
    font-weight: bold;
    background: $button-primary-shadow;
    border: none;
  }
}

.custom-btn-secondary-disabled {
  @include RegularFont(16px, $primary-light, $medium-button-height);
  height: 45px;
  border-width: 0;
  border-radius: 6px;
  border: 1px solid $primary-light;
  background: $white;
}

/*HyperLink Classes*/
.btn-link {
  @include RegularFont(16px, $primary-text-color, 20px);
  margin-right: 37px;
  cursor: pointer;
  text-decoration-line: underline;

  &::after {
    content: attr(title);
    display: block;
    font-family: "PlusJakartaSans-Bold";
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  &.disabled,
  &.disabled:hover {
    @include RegularFont(16px, $link-diabled-txt-color, 23px);
    text-decoration-line: underline;
  }

  &:hover,
  &.active,
  &.selected,
  &.selected:hover {
    font-weight: bold;
    color: $primary-text-color;
    text-decoration-line: underline;
  }
}
