// @mixin font($font-family, $font-file) {
//   @font-face {
//     font-family: $font-family;
//     src: local($font-family), url($font-file + ".ttf") format("truetype");
//     font-display: swap;
//   }
// }

// @include font("PlusJakartaSans-Medium", $plus-JakartaSans-Medium-path);
// @include font("PlusJakartaSans-Bold", $plus-JakartaSans-Bold-path);
// @include font("PlusJakartaSans-Regular", $plus-JakartaSans-Regular-path);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PlusJakartaSans-Regular";
}

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: "PlusJakartaSans-Regular";
}


/* Bootstrap ovverrides*/
ul,
p,
label {
  margin-bottom: 0px;
}

ol, ul {
  padding-left: 0px;
}

.bg-primary{
  background: none;
}

/* Bootstrap overrides ends here*/

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

input,
button,
submit,
textarea {
  border: none;
}

.form-check-input {
  border: 1px solid $secondary-text-color;
  border-radius: 3px;
  &:checked {
    background-color: $primary-dark;
    border-color: $primary-dark;
    border: none;
  }
  &:focus{
    border-color: $secondary-text-color;
    outline: 0;
    box-shadow: none;
  }
}

.custom-input > input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  width: 100%;
  min-height: 50px;
  height: auto;
  color: $primary-text-color;
  background: $white;
  border: 1px solid $border-color-1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 4px $shadow-color;
  box-shadow: 2px 2px 4px $shadow-color;
  border-radius: 6px;
  padding: 0 0 0 10px;

  &:focus {
    outline: none !important;
    border: 1px solid $primary-text-color;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $placeholder-color;
    font-size: 16px;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder-color;
    font-size: 16px;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $placeholder-color;
    font-size: 16px;
    opacity: 1;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $placeholder-color;
    font-size: 16px;
    opacity: 1;
  }

  &.error {
    border: 1px solid $error;
    &:focus {
      outline: none !important;
      border: 1px solid $error;
    }
  }
}

.custom-input > textarea {
  resize: none;
  padding: 5px;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #979faf;
    background-clip: padding-box;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $secondary-text-color;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $secondary-text-color;
  }

  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: #f1f1f1;

    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
    &:hover {
      background-color: #e6e6e6;
    }
  }

  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    border-top-right-radius: 6px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    border-bottom-right-radius: 6px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  /* Left */
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  /* Right */
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
  }
}

.w-max {
  width: 100%;
  max-width: $max-width;
}

.w-text-max {
  width: 100%;
  max-width: $max-text-width;
}

.w-321{
  min-width: 321px;
}

.w-123{
  min-width: 123px;
}

.w-156{
  min-width: 156px;
}

.w-50-p {
  width: 50%;
}

.w-263{
  min-width: 263px;
}

/* All Margins, Padding Classes from 1 to 100 here*/
$max: 100; //You can change the limit Or Add an extra class for specific value
$offset: 1;
$unit: "px";
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}
// Margins
@include list-loop(".m-top-", "margin-top");
@include list-loop(".m-right-", "margin-right");
@include list-loop(".m-bottom-", "margin-bottom");
@include list-loop(".m-left-", "margin-left");
@include list-loop(".m-all-", "margin");

//Extra Margins
.m-bottom-200 {
  margin-bottom: 200px;
}

.m-left-38 {
  margin-left: 39.5%;
}

.m-top-200 {
  margin-top: 200px;
}

// Paddings
@include list-loop(".p-top-", "padding-top");
@include list-loop(".p-right-", "padding-right");
@include list-loop(".p-bottom-", "padding-bottom");
@include list-loop(".p-left-", "padding-left");
@include list-loop(".p-all-", "padding");

.occupy-1 {
  @include GetFlex(1);
}

.occupy-2 {
  @include GetFlex(2);
}

.occupy-3 {
  @include GetFlex(3);
}

.occupy-4 {
  @include GetFlex(4);
}

.flex {
  display: flex;
}

.center-center {
  @include Flex-center;
}

.start-center {
  @include Flex-start-center;
}

.end-center {
  @include Flex-end-center;
}

.start-end {
  @include Flex-start-end;
}

.between-center {
  @include Flex-between-center;
}

.underLine {
  text-decoration: underline;
}

.no-underLine {
  text-decoration: none;
}

.warning {
  background-color: $warning-background;
  p {
    @include MediumFont(14px, $warning, 21px);
  }
}

.success {
  background-color: $success-background;
  p {
    @include MediumFont(14px, $success, 21px);
  }
}

.error {
  background-color: $error-background;
  p {
    @include MediumFont(14px, $error, 21px);
  }
}

.errorText {
  @include RegularFont(14px, $error, 21px);
}

.successText {
  @include RegularFont(14px, $success, 21px);
}

.hide {
  display: none;
}

.gap {
  padding-left: 5%;
  padding-right: 5%;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

/* All Headings here*/

.p-30 {
  @include RegularFont(30px, $primary-text-color, 38px);
}

.p-30-m {
  @include MediumFont(30px, $primary-text-color, 24px);
}

.p-20-m {
  @include MediumFont(20px, $secondary-text-color, 31px);
}

.p-25-light {
  @include RegularFont(25px, $secondary-text-color, 24px);
}

.p1 {
  @include RegularFont(48px, $primary-text-color, 62px);
}

.p2 {
  @include RegularFont(16px, $primary-text-color, 20px);
}

.cprimary {
  color: $primary-dark
}

.p2-regular-20 {
  @include RegularFont(20px, $primary-text-color, 25px);
}

.p2-medium-f-24 {
  @include MediumFont(24px, $primary-text-color, 20px);
}

.p2-medium {
  @include MediumFont(16px, $primary-text-color, 24px);
}

.p2-medium-lh-20 {
  @include MediumFont(16px, $primary-text-color, 20px);
}

.p2-medium-lh-21 {
  @include MediumFont(16px, $primary-text-color, 21px);
}

.p2-medium-lh-20-light {
  @include MediumFont(16px, $secondary-text-color, 20px);
}

.p2-medium-white {
  @include MediumFont(16px, $white, 24px);
}

.p2-medium-light {
  @include MediumFont(16px, $secondary-text-color, 24px);
}

.p2-medium-v2 {
  @include MediumFont(16px, $primary-text-color, 24px);
}

.p2-bold {
  @include BoldFont(16px, $primary-text-color, 20px);
}

.p2-bold-primary{
  @include BoldFont(16px, $primary-dark, 24px);
}

.p2-bold-20 {
  @include BoldFont(20px, $primary-text-color, 20px);
}

.p2-bold-20-v3 {
  @include BoldFont(20px, $primary-text-color, 24px);
}

.p2-bold-20-white {
  @include BoldFont(20px, $white, 24px);
}

.p2-bold-v2 {
  @include BoldFont(16px, $primary-text-color, 24px);
}

.p2-bold-v2-white {
  @include BoldFont(16px, $white, 24px);
}

.p3 {
  @include RegularFont(14px, $primary-text-color, 21px);
}

.p3-medium {
  @include MediumFont(14px, $primary-text-color, 21px);
}

.p3-medium-light-1 {
  @include MediumFont(14px, $secondary-text-color, 21px);
}

.p3-medium-light-2 {
  @include MediumFont(14px, $description-color, 21px);
}

.p3-medium-light-3 {
  @include MediumFont(14px, $description-color, 20px);
}

.p3-light {
  @include RegularFont(14px, $secondary-text-color, 21px);
}

.p3-light-16 {
  @include RegularFont(16px, $secondary-text-color, 20px);
}

.p-small {
  @include RegularFont(14px, $placeholder-color, 18px);
}

.p-description-14 {
  @include RegularFont(14px, $secondary-text-color, 20px);
}

.p-description-16 {
  @include MediumFont(16px, $secondary-text-color, 20px);
}

.p-regular-v4 {
  @include RegularFont(16px, $secondary-text-color, 20px);
}

.bg-grey {
  background: $infobox-bg-color;
}

.bg-primary-light {
  background: $option-background;
}

.w-50 {
  max-width: 50px;
}

.w-200 {
  width: 200px;
}

.w-120 {
  min-width: 120px;
}

.w-150 {
  min-width: 150px;
}

.w-153 {
  width: 153px;
}

.w-487 {
  width: 100%;
  max-width: 487px;
}

.w-523 {
  width: 100%;
  max-width: 523px;
}

.w-1000 {
  width: 1000px;
}

.w-174 {
  min-width: 174px;
}

.w-80-p {
  width: 80% !important;
}

.w-100-p {
  width: 100% !important;
}

.wh-max {
  min-height: 800px;
  height: auto;
  width: 100%;
}

.h-110 {
  min-height: 110px !important;
}

.h-93 {
  min-height: 93px !important;
}

.h-83 {
  min-height: 93px !important;
}

.h-63 {
  height: 63px;
}

.h-45 {
  height: 45px;
}

/*Color Classes*/
.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.basic-multi-select,
.basic-single-select {
  width: 80%;
  box-shadow: 2px 2px 4px $shadow-color;
}

.basic-multi-select-100,
.basic-single-select-100 {
  width: 100%;
  box-shadow: 2px 2px 4px $shadow-color;
}

/*Font-Size Classes*/
.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

/*Font Weights Classes*/
.f-weight-500 {
  font-weight: 500;
}

/*Border Classes*/
.border-1 {
  border: 1px solid $nav-sticky-border;
}

.border-2 {
  border: 2px solid $icon-color;
}

.border-r-20 {
  border-radius: 20px;
}

.border-r-7 {
  border-radius: 7px;
}

.border-error {
  border: 1px solid $error !important;
}
.no-bottom-radius {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.underline {
  display: block;
  height: 0.5px;
  border: 0;
  border-top: 0.5px solid $border-separation-color;
  padding: 0;
}

.content-gap {
  width: 90%;
}

.breakpoint-gap-p {
  padding: 0 $breakpoint-gap 0 $breakpoint-gap;
}

.text-inbetween-Link {
  display: inline-block;
  letter-spacing: 0px;
  a {
    @include RegularFont(16px, $primary-text-color, 20px);
    display: inline-block;
    text-align: center;
    &:hover {
      font-family: "PlusJakartaSans-Bold";
      letter-spacing: 0px;
    }
  }

  & a::before {
    content: attr(title);
    display: block;
    font-family: "PlusJakartaSans-Bold";
    letter-spacing: 0px;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
}

.hyperlink-active {
  @include RegularFont(16px, $primary-text-color, 24px);
  text-decoration: underline;
  &:hover {
    font-weight: bold;
    color: $primary-text-color;
  }
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
}

.pointer {
  cursor: pointer;
}

.img-objectfit{
  object-fit: cover;
}
