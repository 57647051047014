#dropdown-header {
    @include GetFlex(1);
    height: 47px;
    cursor: pointer;
    img {
        width: 32px;
        height: 32px;
    }

    & span {
        @include RegularFont(16px, $secondary-text-color, 20px);
        padding: 0 0 0 10px;
    }
}

#dropdown-list-container {
    position: absolute;
    flex-direction: column;
    background: $white;
    border: 1px solid $border-color-1;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px $shadow-color;
    border-radius: 0px 0px 6px 6px;
    z-index: 100;
}

#dropdown-list {
    height: 41px;
    & .options {
        display: flex;
        align-items: center;
        height: 100%;
        width: 80%;
        margin: 0 10% 0 10%;
        border-bottom: 1px solid $dropdown-separation-color;
        cursor: pointer;
        span {
            @include RegularFont(14px, $primary-text-color, 18px);
            opacity: 0.7;
        }
    }

    &:hover {
        background: $option-background;
        border-left: 1px solid $primary-dark;
    }
}

.headerShadow {
    border: 1px solid $border-color-1;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px $shadow-color;
    border-radius: 6px 6px 0px 0px;
}

.accountProfileImage {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}
