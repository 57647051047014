#footer {
  display: flex;
  flex-direction: column;
  background: $secondary-text-color;
  border: 1px solid $transparent-border;
  box-shadow: 0px 4px 7px $nav-shadow-color;
  min-height: 129px;
}

.footer-links {
  @include GetFlex(1);
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 6.5px;
  margin-top: 6.5px;
  
  & a:hover {
      font-weight: bold;
  }

  & li {
      list-style: none;
  }

  & .dot {
    height: 5px;
    width: 5px;
    margin-bottom: 2px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
  }
}

#upperFooter {
  @include GetFlex(1);
  align-items: flex-end;
}

#lowerFooter {
  @include GetFlex(1);
  align-items: flex-start;
}

.fo-upperLinks,.fo-lowerLinks {
  padding-left: 10px;
}

.fo-upperLinks a {
  @include BoldFont(14px, $white);
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}

.fo-lowerLinks a {
  @include RegularFont(14px, $white);
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}