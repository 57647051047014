@mixin flexBox($justifyContent: center, $alignItems: center, $direction: row) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-direction: $direction;
  }

.logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 65px;
    img {
      width: 128px;
      height: 33px;
      padding-right: 7px;
      cursor: pointer;
    }
  }
  
  .nav-bar {
    @include flexBox(space-between, center);
    background-color: $white;
    min-height: 65px;
    box-shadow: 0px 4px 7px $nav-shadow-color;
  }
  
  .nav-links {
    display: flex;
    min-height: 65px;
    li {
      list-style: none;
    }
  
    p {
      @include RegularFont($h3-text, $secondary-text-color, 20px);
      text-decoration: none;
      margin: 0 5px;
    }
  
    p:hover,
    .active {
      color:$placeholder-color;
      font-weight: bold;
      cursor: pointer;
    }
  }

 li#eng-lang {
    list-style: none !important;
    margin-right: 10px;
  }

  #deutch-lang > li::before {
    list-style: none !important;
  }

  .navbar-content {
    margin: 0 5% 0 5%;
  }

  .nav-bar-sticky-border {
    border-bottom : 2px solid $nav-sticky-border;
    box-shadow: none;
  }
