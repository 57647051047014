$percent-100: 100%;
$px-100: 100px;
$px-0: 0px;

.clsLoader-container {
  position: fixed;
  right: 0px;
  top: 0px;
  width: $percent-100;
  height: $percent-100;
  margin: $px-0;
  padding: $px-0;
  background-color: $transparent-white-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.second-clsLoader-container {
  position: fixed;
  right: 0px;
  top: 0px;
  width: $percent-100;
  height: $percent-100;
  margin: $px-0;
  padding: $px-0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: #ffffff0a;
}

.clsLoader-img {
  width: $px-100;
  height: $px-100;
}

.clsLoaderDiv {
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;
  background-color: $transparent-white-bg;
  flex: 1 1 0%;

  z-index: 2000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}