#message-bar {
  height: auto;
  min-height: 99px;
  //padding: 0% 5% 0% 5%;
  p {
    @extend .content-gap;
  }
}

#information-bar {
  height: auto;
  background: $infobox-bg-color;
  .info-icon {
    margin: 0 10px 0 0px;
    //color: $secondary-text-color;
    color: $icon-color;
  }
  p {
    @include MediumFont(14px, $secondary-text-color, 21px);
  }
}

#information-bar-light {
  height: auto;
  background: $white;
  border: 1px solid $infobox-light-border;
  .info-icon {
    margin: 0 10px 0 0px;
    //color: $secondary-text-color;
    color: $secondary-text-color;
  }
  p {
    @include MediumFont(14px, $secondary-text-color, 21px);
  }
}

.success-icon {
  color: $success;
}

.multiSelect-container {
  width: 490px;
  margin-top: 17px;
}

.edit-icon {
  position: relative;
  cursor: pointer;
  left: 124px;
  bottom: 45px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: $white;
  box-shadow: 0px 4px 4px 5px $nav-shadow-color;
}

.profile-card-border {
  border: 1px solid $border-color-1;
  border-radius: 10px;
}

.spinner-border {
  width: 162px;
  height: 162px;
  border: 20px solid $primary-light;
  border-right-color: $primary-dark;
}

.badge-pill {
  @include MediumFont(14px, $primary-text-color, 21px);
  border-radius: 16px;
  min-width: 96px;
}

.badge {
  min-height: 27px;
  font-weight: 500;
  padding: 2px 8px 0 8px;
}

.score-card-border {
  border: 1px solid $border-color-1;
  box-shadow: 2px 2px 4px $shadow-color;
  border-radius: 11px;
}

.score-card-text-border {
  border-bottom: 0.5px solid $border-separation-color;
}

.border-seconday {
  border-bottom: 0.5px solid $secondary-text-color;
}


.accordion-button {
  background: $secondary-text-color;
  color: white;
  &:not(.collapsed) {
    background: $secondary-text-color;
    color: white;
    &::after {
      background-image: url("../images/arrow_white.svg");
      transform: rotate(180deg);
    }
  }
  &:focus {
    border-color: none;
    box-shadow: none;
  }

  &::after {
    background-image: url("../images/arrow_white.svg");
    transform: rotate(360deg);
  }
}

.accordion-button:hover {
   z-index: unset
}

.mixed {
  & .accordion-button { 
    background: linear-gradient(to right, $icon-color 0%,$icon-color 15%,$black 15%, $secondary-text-color 15%, $secondary-text-color 100%);
  }
  &:not(.collapsed) {
    background: linear-gradient(to right, $icon-color 0%,$icon-color 15%,$black 15%, $secondary-text-color 15%, $secondary-text-color 100%);    
  }
 }


.approve-label {
  height: 41px;
  background: $success;
  border-radius: 0px 0px 6px 6px;
  margin-top: 37px;
  & p {
    @include RegularFont(14px, $white, 18px);
  }
}

.cProfileImg {
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.paragraph-container {
  background: $neutral-background;
  box-shadow: 2px 2px 4px $shadow-color;
  border-radius: 11px;
  min-height: 300px;
  padding: 20px 34px 34px 32px;
}

.paragraph-container-light {
  @extend .paragraph-container;
  background: $white;
  box-shadow: none;
  padding-left: 0px;
}

.paragraph-container-full {
  @extend .paragraph-container-light;
  padding: 20px 0px 34px 0px;
}

.displayCard {
  height: 62px;
  background: $secondary-text-color;
  border-radius: 6px;
}

.user-card {
  min-height: 404px;
  height: 100%;
  background: $white;
  border-radius: 6px;
  padding: 24px 40px;
  & .user-card-header {
    // height: 50px;
    border-bottom: 0.5px solid $border-separation-color;
  }
}

.export-checkbox {
  & .form-check-label {
    @include BoldFont(20px, $primary-text-color, 24px);
  }
}

.grey-container {
  background: $infobox-bg-color;
  border-radius: 6px;
  padding: 12px 0 16px 23px;
}

.content-wrapper {
  min-height: 100vh;
}

.displaybox-grey {
  width: 100%;
  background: $secondary-text-color;
  border-radius: 6px;
  height: 62px;
}

#TimerCard {
  & .timer-section {
    border: 1px solid $border-color-1;
    border-radius: 6px 0px 0px 6px;
  }
  & .timer-text-section {
    border: 1px solid $border-color-1;
    border-radius: 0px 6px 6px 0px;
    & p {
      font-size: 16px;
      line-height: 20px;
    }   
    & span {
      font-family: "PlusJakartaSans-Medium";
      font-size: 16px;
      line-height: 24px;
      color: $primary-dark;
    }
  }
}

#TimerCard-mini {
  & #TimerCard-top {
    border: 1px solid $border-color-1;
    border-radius: 6px 6px 0px 0px;
  }
  & #TimerCard-bottom {
    border: 1px solid $border-color-1;
    border-radius: 0px 0px 6px 6px;

    & .timer-date{
      font-family: "PlusJakartaSans-Medium";
      font-size: 16px;
      line-height: 24px;
      color: $primary-dark;
    }
  }
}

.disbale-matching-bottom {
  border-radius: 0px 0px 6px 6px;
}

.show-small {
  display: none;
}

.hide-small {
  display: flex;
}

.errorPage {
  & .errorLink {
      & a {
        color: $primary-text-color;
      }

      & a:hover {
        font-weight: bold;
      }
  }
}

.remove-icon {
    position: relative;
    cursor: pointer;
    left: 86px;
    bottom: 62px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: $white;
    box-shadow: 0px 4px 4px 5px $nav-shadow-color;
}

.deactivateIcon {
  width: 33px;
  height: 28px;
  color: $white
}

.proposals-container {
    display: flex;
    flex-direction: column;
}

.seperator {
  border: 10px solid $secondary-text-color;
  color: $secondary-text-color;
  opacity: 1;
  
  width: 100vw;
  box-sizing: border-box; /* Include padding in the width */
  
  margin-top: 100px;
  margin-bottom: 100px;
}