.lg-row-sm-col {
    flex-direction: row;
}

.app-standard-gap {
    padding-left: 60px;
    padding-right: 60px;
}

@media screen and (max-width: 1000px) {
    .lg-row-sm-col{
        flex-direction: column;
    }
    .clear-p-left {
        padding-left: 0px;
    }
    .mobile-top-60 {
        margin-top: 60px;
    }
    .mobile-center-center {
        align-items: center;
        justify-content: center;
    }
    .app-standard-gap {
        padding-left: 20px;
        padding-right: 20px;
    }
    .app-standard-gap-left {
        padding-left: 20px;
    }
    .mobile-gap > a {
        margin: 30px;
    }
    .mobile-gap > div {
        margin: 10px 10px;
        &>button {
            margin: 10px 10px; 
        }
    }
    .mobile-gap > button {
        margin: 10px 0;
    }

    .footer-links {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .show-small {
        display: block;
        margin-top: 10px;
      }
      
    .hide-small {
        display: none;
    }

    .w-487, .w-523 {
        max-width: none;
    }
}

@media screen and(max-width: 766px) {
        & .mixed {
            & .accordion-button {
                & .heading-1 {
                    margin-bottom: 10px;
                }
                & .heading-2 {
                    margin-top: 10px;
                }
                &::after {
                    margin-top: 10%;
                }
                background: linear-gradient(to bottom, $icon-color 0%,$icon-color 50%,$black 50%, $secondary-text-color 50%, $secondary-text-color 100%);
                &:not(.collapsed) {
                    background: linear-gradient(to bottom, $icon-color 0%,$icon-color 50%,$black 50%, $secondary-text-color 50%, $secondary-text-color 100%);
                }
            }
        }

        .show-small {
            display: block;
            margin-top: 10px;
          }
          
        .hide-small {
            display: none;
        }
}

@media screen and(max-width: 810px) {
    .show-small {
        display: block;
        margin-top: 10px;
      }
      
    .hide-small {
        display: none;
    }
}