.pagination-container {
    height: 56px;
    background: $pagination-panel-bg;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination {
    justify-content: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul.pagination li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height:50px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: $primary-text-color;
  }
  
  ul.pagination li a {
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: $primary-text-color;
  }
  
  ul.pagination li.active a {
    color: $white;
  }

  ul.pagination li.active {
    background-color: $table-background;
    &:hover{
      color: $white;
    }
  }
  
  ul.pagination li a:hover,
  ul.pagination li a.active {
    font-weight: bold;
  }

  