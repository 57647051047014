.modal-backdrop{
 background: $modal-shadow;
}

.modal-content  {
    border-radius: 9px;
    border: none;
}
.model-w30{
 width: 30%;
}