.table {
    width: 100%;
    border-collapse: separate !important;
    border-spacing:0 20px;
    color: $primary-text-color;
}

.table>:not(:first-child) {
     border-top: none; 
}

.table {
     th {
        background: $table-background;
        box-shadow: 2px 2px 4px $shadow-color;
        color: $white;
        font-family: "PlusJakartaSans-Bold";
        font-size: 16px;
        line-height: 20px;
        padding-left: 24px;
        vertical-align: middle!important;
    }
}

 tr,th {
    box-shadow: 2px 2px 4px $shadow-color;
    color: $primary-text-color;
    height: 56px;
    // cursor: pointer;
 }

.table td:first-child, th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
    border-left: 1px solid $border-color-2;
}

.table td:last-child, th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
    border-right: 1px solid $border-color-2;
}

.table td {
    font-family: "PlusJakartaSans-Medium";
    font-size: 16px;
    line-height: 20px;
    border-top: 1px solid $border-color-2;
    border-bottom: 1px solid $border-color-2;
    padding-left: 24px;
    vertical-align: middle!important;

    &.selected {
        background: $secondary-text-color !important;
        color: $white;
     }
}

.overflow {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

tbody >tr:nth-child(odd) {
    background:white;
 }

 tbody >tr:nth-child(even) {
    background: $neutral-background;
 }
 #toogleRow {
     background-color: $white;
 }

