
// Fonts
$font-path: '../../assets/fonts/';
$plus-JakartaSans-Regular-path: $font-path+'PlusJakartaSans-Regular';
$plus-JakartaSans-Medium-path: $font-path+'PlusJakartaSans-Medium';
$plus-JakartaSans-Bold-path: $font-path+'PlusJakartaSans-Bold';

$max-width: 1400px;
$max-text-width: 1024px;
$breakpoint-gap: 60px;

//Colors
$black : var(--black);
$white: var(--white);
$primary-dark: var(--primary-color);
$primary-light: var(--secondary-color);
$success: var(--success-color);
$warning : var(--warning-color);
$error: var(--error-color);
$success-background: var(--success-bg-color);
$warning-background: var(--warning-bg-color);
$error-background: var(--error-bg-color);
$neutral-background: var(--neutral-bg);
$table-background: var(--table-bg-color);
$option-background: var(--option-bg-color);
$primary-text-color: var(--primary-text-color);
$secondary-text-color: var(--secondary-text-color);
$description-color: var(--description-color);
$placeholder-color:  var(--placeholder-color);
$icon-color: var(--icons-color);
$border-color-1: var(--border-color);
$border-color-2: var(--border-color-light);
$dropdown-separation-color: var(--dropdown-separation-color);
$border-separation-color : var(--border-separation-color);
$transparent-white-bg: var(--transparent-white-bg);
$shadow-color: var(--shadow-color);
$modal-shadow: var(--modal-shadow-color);
$pagination-panel-bg: var(--pagination-panel-bg);
$nav-shadow-color: var(--nav-shadow-color);
$nav-sticky-border: var(--nav-sticky-border);
$transparent-border: var(--transparent-border);
$button-primary-shadow: var(--button-primary-shadow);
$button-disabled-shadow: var(--button-disabled-shadow);
$link-diabled-txt-color: var(--link-diabled-txt-color);
$infobox-bg-color: var(--infobox-bg-color);
$infobox-light-border: var(--infobox-light-border);


$medium-button-height:20px;
$large-button-height:23px;

//Text-Sizes
$h1-text: 48px;
$h2-text: 20px;
$h3-text: 16px;
$descriptive-text: 14px;

@mixin BoldFont($fontSize, $fontColor: $black, $lineHeight : 20px) {
    font-family: "PlusJakartaSans-Bold";
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin RegularFont($fontSize , $fontColor: $black, $lineHeight : 20px) {
    font-family: "PlusJakartaSans-Regular";
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin MediumFont($fontSize , $fontColor: $black, $lineHeight : 20px) {
    font-family: "PlusJakartaSans-Medium";
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin GetFlex($flexNumber : 1) {
    display: flex;
    flex: $flexNumber;
}

@mixin Flex-center {
    justify-content: center;
    align-items: center;
}

@mixin Flex-start-center {
    justify-content: flex-start;
    align-items: center;
}

@mixin Flex-end-center {
    justify-content: flex-end;
    align-items: center;
}

@mixin Flex-start-end {
    justify-content: flex-start;
    align-items: flex-end;
}

@mixin Flex-between-center {
    justify-content: space-between;
    align-items: center;
}
